body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

.badge {
  vertical-align : inherit;
  font-size : 24px;
}


.glyphicon {
  cursor: pointer
}

.datePicker{
  max-width: 110px;
  text-align: center;
}

.marginRight {
  margin-right: 12px !important;
}

.marginLeft {
  margin-left: 18px !important;
}

.marginBottom {
  margin-bottom: 10px !important;
}
/*
   _________                    __      ________              __          
  /  _____/____    ____ _____ _/  |_   /  _____/ __ _________/  |______   
  \_____  \\__  \  /    \\__  \\   __\ /   \  ___|  |  \____ \   __\__  \  
  /        \/ __ \|   |  \/ __ \|  |   \    \_\  \  |  /  |_> >  |  / __ \_
 /_______  (____  /___|  (____  /__|    \______  /____/|   __/|__| (____  /
         \/     \/     \/     \/               \/      |__|             \/ 

    This progam is made by Sanat Gupta.
    © sanat gupta 2021
 */
.card {
    width: 350px;
    padding: 10px;
    border-radius: 20px;
    background: #fff;
    border: none;
    height: 350px;
    position: relative

}

.container {
    height: 50vh
}

body {
    background: #eee
}

.mobile-text {
    color: #989696b8;
    font-size: 15px
}

.form-control {
    margin-right: 12px
}

.w-3em {
    width: 3em !important;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ff8880;
    outline: 0;
    box-shadow: none
}

.cursor {
    cursor: pointer
}
.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.panel {
  background-color:floralwhite;
  border: gainsboro 1px solid;
}

.panel .panel-heading {
  cursor: pointer;
} 
.panel .panel-collapse {
    overflow: hidden;
    -webkit-transition: height 0.3s ease-out;
    transition: height 0.3s ease-out;  
}
.panel  .panel-body {
    border: none !important;
}
.panel h2 {
  margin-top: 5px !important; 
  text-transform: capitalize
}


.options-margin-top {
  margin-top: 10px;
  cursor: pointer;
}

.root {
  flex-grow: 1,
}

.grow {
  flex-grow: 1,
}

.container {
  text-align: center;
  padding: 15px 5px 15px 5px;
  margin-left: 8px;
  margin-bottom: 10px;
  width: 98% !important;
  padding-top: 50px
}

.w-3em {
  width: 3em !important;
}

.w-2em {
  width: 40px !important;
}
@media all and (min-width: 480px) {
    .Login {
      padding: 0 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
}
.NotFound {
    padding-top: 100px;
    text-align: center;
}
.links-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.fas {
  padding: 20px;
  font-size: 30px;
  width: 70px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fas:hover {
  opacity: 0.7;
}

.fab {
  padding: 20px;
  font-size: 30px;
  width: 70px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fab:hover {
  opacity: 0.7;
}

.fa-facebook-f {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: #f09433;
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  color: white;
}

.fa-pinterest-p {
  background: #cb2027;
  color: white;
}

.fa-whatsapp {
  background: green;
  color: white;
}
.fa-amazon {
  background: #ffa000;
  color: black;
}
.fa-globe-asia {
  background: #2c4762;
  color: white;
}
.fa-telegram-plane {
  background: #4e99c7;
  color: white;
}
.fa-headset {
  background: #c2c2c2;
  color: black;
}
.fa-envelope-open-text {
  background: #f8aa00;
  color: white;
}

