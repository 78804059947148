.links-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.fas {
  padding: 20px;
  font-size: 30px;
  width: 70px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fas:hover {
  opacity: 0.7;
}

.fab {
  padding: 20px;
  font-size: 30px;
  width: 70px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fab:hover {
  opacity: 0.7;
}

.fa-facebook-f {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  color: white;
}

.fa-pinterest-p {
  background: #cb2027;
  color: white;
}

.fa-whatsapp {
  background: green;
  color: white;
}
.fa-amazon {
  background: #ffa000;
  color: black;
}
.fa-globe-asia {
  background: #2c4762;
  color: white;
}
.fa-telegram-plane {
  background: #4e99c7;
  color: white;
}
.fa-headset {
  background: #c2c2c2;
  color: black;
}
.fa-envelope-open-text {
  background: #f8aa00;
  color: white;
}
