/*
   _________                    __      ________              __          
  /  _____/____    ____ _____ _/  |_   /  _____/ __ _________/  |______   
  \_____  \\__  \  /    \\__  \\   __\ /   \  ___|  |  \____ \   __\__  \  
  /        \/ __ \|   |  \/ __ \|  |   \    \_\  \  |  /  |_> >  |  / __ \_
 /_______  (____  /___|  (____  /__|    \______  /____/|   __/|__| (____  /
         \/     \/     \/     \/               \/      |__|             \/ 

    This progam is made by Sanat Gupta.
    © sanat gupta 2021
 */
.card {
    width: 350px;
    padding: 10px;
    border-radius: 20px;
    background: #fff;
    border: none;
    height: 350px;
    position: relative

}

.container {
    height: 50vh
}

body {
    background: #eee
}

.mobile-text {
    color: #989696b8;
    font-size: 15px
}

.form-control {
    margin-right: 12px
}

.w-3em {
    width: 3em !important;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ff8880;
    outline: 0;
    box-shadow: none
}

.cursor {
    cursor: pointer
}